import { graphql } from 'gatsby'
import React from 'react'
import { BlogSpot } from '../../components/BlogSpot'
import { Container } from '../../components/Container'
import { Divider } from '../../components/Divider'
import { Grid } from '../../components/Grid'
import { LoadMore } from '../../components/LoadMore'
import { MagazineContainer } from '../../components/MagazineContainer'
import { MagazineFeaturedArticle } from '../../components/MagazineFeaturedArticle'
import { SPACING } from '../../helpers/constants'
import { parseSeo } from '../../helpers/seo'
import { usePageData } from '../../helpers/utilities'
import { useTranslation } from '../../hooks/useTranslation'
import Layout from '../../views/Layout'
import MagazineHeader from '../../views/MagazineHeader'
import SEO from '../../views/Seo'

export default ({ data, pageContext }) => {
  const { page, posts, stickyPosts } = usePageData(data, pageContext)
  const { seo } = page

  const getTranslation = useTranslation()

  const featuredPost = stickyPosts?.nodes?.[0]
  const allPosts = posts?.nodes?.filter(post => post.id !== featuredPost?.id)

  const postsPerPage = 12
  const [gridPage, setGridPage] = React.useState(1)
  const postsToShow = allPosts.slice(0, gridPage * postsPerPage)
  const hasMorePosts = postsToShow.length < allPosts.length

  return (
    <Layout>
      <SEO {...parseSeo(seo)} />
      <MagazineHeader />
      <MagazineContainer>
        <Container>
          <Divider
            size={{
              desktop: SPACING.LARGE,
              tablet: SPACING.MEDIUM,
              mobile: SPACING.SMALL,
            }}
          />

          {featuredPost && <MagazineFeaturedArticle post={featuredPost} />}

          <Divider
            size={{
              desktop: SPACING.LARGE,
              tablet: SPACING.MEDIUM,
              mobile: SPACING.SMALL,
            }}
          />

          <Grid col={4}>
            {postsToShow.map((post, index) => (
              <BlogSpot post={post} key={index} small />
            ))}
          </Grid>

          {allPosts.length > postsPerPage && (
            <LoadMore
              label={getTranslation('loadMoreArticles')}
              onLoadMore={() => setGridPage(page => page + 1)}
              disabled={!hasMorePosts}
            />
          )}

          <Divider
            size={{
              desktop: SPACING.LARGE,
              tablet: SPACING.MEDIUM,
              mobile: SPACING.SMALL,
            }}
          />
        </Container>
      </MagazineContainer>
    </Layout>
  )
}

export const query = graphql`
  query($id: ID!) {
    wordpress {
      page(id: $id) {
        ...Single_Page
      }
    }
  }
`
